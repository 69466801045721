import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import logo from '../../../assets/images/logo.png'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Toast from 'react-bootstrap/Toast';

import Footer from '../helper/Foteer'
import './contact.css'
import {Link} from 'react-router-dom'
   const sendData  = () => {
    return (
      <Toast>
      <Toast.Header>
      <img   src="holder.js/20x20?text=%20"  />
        <strong className="me-auto">Bootstrap</strong>
        <small>11 mins ago</small>
      </Toast.Header>
      <Toast.Body>Hello, world! This is a toast message.</Toast.Body>
    </Toast>
    )
   }

function Contact() {

  const servicesList = [
    {
      name: 'Mobile App',
    },
    {
      name: 'E-commerce',
    },
    {
      name: 'Web desgin',
    },
    {
      name: 'System design',
    },
    {
      name: 'Graphics',
    },
    {
      name: 'Marketing',
    }
  ]

    return (
      <>  

          <div   style={{  margin:'auto' , width:'80%' , textAlign:'center' , marginTop:70}} >

         

          <Container className='conta'  >

            <Col>
              <Card className='card-contact' >
                    <img  style={{width:50 , height:'auto' , margin:'auto'}} src='https://5gfordesign.sa/wp-content/themes/5gfordesign/images/contact-card.png' />
                    <h6>Phone Number</h6>
                    <h6>+218 91-5990985</h6>
              </Card>     
            </Col>


            <Col>
            <Card className='card-contact' >
                  <img  style={{width:65 , height:'auto' , margin:'auto'}} src='https://5gfordesign.sa/wp-content/themes/5gfordesign/images/email-card.png' />
                  <h6>E-mail</h6>
                  <h6>info@wahatech.ly</h6>
               
               
            </Card>     
            </Col>



            <Col>
            <Card  className='card-contact' >
                  <img  style={{width:50 , height:'auto' , margin:'auto'}} src='https://5gfordesign.sa/wp-content/themes/5gfordesign/images/location-card.png' />
                  <h6>Location</h6>
                  <h6>Tripoli Libya</h6>
            </Card>     
            </Col>



  
          </Container>

          </div>



          <h1 style={{color:'#744F9C' , textAlign:'center' , marginTop:70}}>price-offer</h1>
                <p className='paragrap-contact' >We're here to help. Whatever you want to discuss, we love to hear about your business goals and we can provide strategies</p>

        <Container  className='order-form'>
        

         <Col>
          <Row style={{marginTop:80}}>
            {servicesList.map(index => {
                return (
                      <Col xs={4}>
                      <Form.Check className='check-from' label={index.name}/>
                      </Col>
               )})}
           </Row>
         </Col>
              
         <Col>
          <Form  className='form' >
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Your Name</Form.Label>
          <Form.Control type="email" placeholder="Enter Your Name" />
        
        </Form.Group>
  
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" placeholder="Email" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Message</Form.Label>
          <Form.Control type="text" placeholder="Enter Message Content" />
        </Form.Group>
        <Button className='send' onClick={sendData()}  variant="primary" type="submit" >
          Send 
        </Button>
      </Form>
          </Col>
      </Container>
  





        <div style={{marginTop:100}} >
        <Footer  />
        </div>

      </>


)
    }
export default Contact
