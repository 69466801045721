import React from 'react'
import Container from "react-bootstrap/Container";


import {Link} from 'react-router-dom'
import logo from '../../../assets/images/logo.png'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'

import vr from '../../../assets/images/VR.png'
import b2b from '../../../assets/images/b2b.png'
import mobile from '../../../assets/images/mobile.png'
import sec from '../../../assets/images/security1.png'
import techsupport from '../../../assets/images/tech support (2).png'
import ui from '../../../assets/images/ui.png'
import webdev from '../../../assets/images/webdev.png'
import { useNavigate } from 'react-router-dom';
import './servicses.css'
import Foteer from '../helper/Foteer';
function Services() {
  const  navigate = useNavigate();
  const Go_to_page = (e, path) => {
    e.preventDefault();
    navigate(`${path}`);
  }
  return (
      <>

<div className="con">

  
<div id="divserv">
</div>  

  <div  className="hero" style={{ height: '60vh', backgroundColor: 'black', width: '100%', display: 'flex', textAlign: 'center', justifyContent: 'center', flexDirection: 'column' }}>
    <Container>

      <div style={{ width: '60%', color: 'white', margin: 'auto' }} >
        <h1>OUR SERVICES </h1>
     

      </div>



    </Container>


  </div>

  </div>



<div style={{ padding: 100 }}>


<Container>




  <Row >




    <Col className="service-card" >
      <Row>
        <img style={{ padding: 40 }} src={ui} />
      </Row>
      <Row>
        <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>  UI/UX</h3>
      </Row>
      <Row>

      </Row>
    </Col>





    <Col className="service-card" >
      <Row>
        <img style={{ padding: 40 }} src={vr} />
      </Row>
      <Row>
        <h3 style={{ textAlign: 'center', fontWeight: 'bold' }} >Artificial Intelligent</h3>

      </Row>
      <Row>

      </Row>
    </Col>






    <Col className="service-card" >
      <Row>
        <img style={{ padding: 40 }} src={sec} />
      </Row>
      <Row>
        <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>Security</h3>
      </Row>
      <Row>

      </Row>
    </Col>





    <Col className="service-card" >
      <Row>
        <img style={{ padding: 40 }} src={techsupport} />
      </Row>
      <Row>
        <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}> Technical support services</h3>
      </Row>
      <Row>

      </Row>
    </Col>



  </Row>









  <Row >
    <Col className="service-card" >
      <Row>
        <img style={{ padding: 40 }} src={vr} />
      </Row>
      <Row>
        <h3 style={{ textAlign: 'center', fontWeight: 'bold' }} >Vr Services</h3>
      </Row>
      <Row>

      </Row>
    </Col>





    <Col className="service-card" >
      <Row>
        <img style={{ padding: 40 }} src={b2b} />
      </Row>
      <Row>
        <h3 style={{ textAlign: 'center', fontWeight: 'bold' }} > B2B and B2C Commerce</h3>
      </Row>
      <Row>

      </Row>
    </Col>






    <Col className="service-card" >
      <Row>
        <img style={{ padding: 40 }} src={mobile} />
      </Row>
      <Row>
        <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>  mobile App Development </h3>
      </Row>
      <Row>

      </Row>
    </Col>





    <Col className='service-card'>
      <Row >
        <img style={{ padding: 40 }} src={webdev} />
      </Row>
      <Row>
        <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>Web applications Development</h3>
      </Row>
      <Row>

      </Row>
    </Col>



  </Row>


  <div className='btn-div' style={{  width: '50%', margin: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
    <button  onClick={ e => Go_to_page (e , '/contact')}className="btn btn-danger btnc" style={{ marginTop: 60, width: 200 }}>Contact us</button>

  </div>

</Container>


</div>
    <Foteer />
      </>

  )
}

export default Services
