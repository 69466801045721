/* eslint-disable react/jsx-no-undef */
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo_1.png";
import Man from "../../assets/images/manlaptop.png";
import Rocket from "../../assets/images/rocket.png";
import Golbal from "../../assets/images/global.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import '../ar.css'
import { FaUsers, FaCheck, FaPollH, FaTasks } from "react-icons/fa";


function Home() {
  return (
    <>
      <Container style={{ marginTop: 100  }}>
        <Row>
          <Col className="header-right" >
            <h1>إحدى الشركات الرائدة في مجال    {" "}
              <span style={{ color: "#F55F7F" }}>Technology</span> الحلول
              التقنية في <span style={{ color: "#9E5FF2" }}> ليبيا</span>
            </h1>
          </Col>
          <Col  >
            <img
            alt=""
              className="manImage"
              src={Man}
              style={{
                height: "auto",
                width: 623,
            
              }}
            />
          </Col>
        </Row>
      </Container>

      <Container style={{ marginTop: 100 }}>
        <h1
          style={{
            color: "#002581",
            fontSize: 51,
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          Overview
        </h1>
        <Row>
          <Col
        
            className="header-right"
          >
            <img
            alt=""
              src={Rocket}
              style={{
                height: "auto",
                width: 570,
              }}
            />
          </Col>

          <Col>
            <p
              style={{
                marginTop: "150px",
                fontSize: 25,
                width: 515,
                height: 510,
                float: "right",
              }}
            >
              الواحة للخدمات التقنية هي شركة ليبية متخصصة في مجال تقنية وأمن المعلومات، حيث نهدف إلى تسخير التكنولوجيا في تسهيل الأعمال عن طريق إنشاء حلول تقنية تساهم في عملية التحول الرقمي للشركات حيث نقدم خدمات تطوير مواقع الإنترنت وتطبيقات الموبايل مدمجة بأحدث تقنيات الذكاء الاصطناعي و الواقع الإفتراضي بالاضافة إلى أنظمة إدارة موارد المؤسسات وأنظمة المبيعات وتوفير البُنية التحتية والاستشارات التقنية اللاّزمة للمؤسسات لسلاسة عملها و لتأمين البيانات ومتابعة أمنها السيبراني.
    
            </p>

          </Col>
        </Row>
        <Button style={{  }} variant="outline-danger">
                More About Us{" "}
              </Button>
      </Container>

      <Container style={{ marginTop: 0 }}>
        <h1
          style={{
            color: "#002581",
            fontSize: 51,
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          What We Do ?
        </h1>
        <Row>
          <Col>
            <p
              style={{
                fontSize: 25,
                width: 550,
                height: 510,
                marginTop: 130,
              }}
            >
              It's branched from the most famous Expo’s Organizing companies in
              Libya. This gives us the Access to the biggest and most quality
              companies database in Libya and we were able to get their trust.
              We worked with both private and public companies and institutions
              in Libya.More About Us
              <Button style={{ marginTop: 40 }} variant="outline-danger">
                More About Us
              </Button>
            </p>
          </Col>

          <Col
        
            className="header-right"
          >
            <img
            alt=""
              src={Golbal}
              style={{
                float: "right",
                height: "auto",
                width: 516,
                right: 150,
              }}
            />
          </Col>
        </Row>
      </Container>

      <Container>
        <div
          style={{
            alignItems: "stretch",
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            width: "70%",
          }}
        >
          <Row>
            <Col>
              <Card
                style={{
                  margin: "auto",
                  width: "18rem",
                  textAlign: "center",
                  border: 0,
                  height: 350,
                }}
              >
                <FaUsers
                  style={{ fontSize: "6rem", margin: "auto", color: "#DD3545" }}
                />
                <Card.Body style={{ position: "relative" }}>
                  <Card.Title style={{fontWeight:'bold'}}>زبائننا هم أصدقائنا</Card.Title>
                  <Card.Text>
                  نحن نعامل زبائننا مثل أصدقائنا و نبقى معهم من بداية فكرة المشروع 
وحتى أي وقت يكونون في حاجة إلينا.
                  </Card.Text>
                  <Button
                    variant="outline-danger"
                    style={{
                      position: "absolute",
                      bottom: 5,
                      marginLeft: "auto",
                      marginRight: "auto",
                      left: 10,
                      right: 10,
                    }}
                  >
                    
                    More
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card
                style={{
                  margin: "auto",
                  width: "18rem",
                  textAlign: "center",
                  border: 0,
                  height: 350,
                }}
              >
                <FaCheck
                  style={{ fontSize: "6rem", margin: "auto", color: "#DD3545" }}
                />
                <Card.Body style={{ position: "relative" }}>
                  <Card.Title style={{fontWeight:'bold'}}>المسؤولية والإنجاز</Card.Title>
                  <Card.Text>
                  نفي بمسؤوليتنا تجاه عملائنا والتزامنا بسرعة إنجاز ما تم الاتفاق عليه.
                  </Card.Text>
                  <Button
                    variant="outline-danger"
                    style={{
                      position: "absolute",
                      bottom: 5,
                      marginLeft: "auto",
                      marginRight: "auto",
                      left: 10,
                      right: 10,
                    }}
                  >
                    {" "}
                    More{" "}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row style={{ marginTop: 50 }}>
            <Col>
              <Card
                style={{
                  margin: "auto",
                  width: "18rem",
                  textAlign: "center",
                  border: 0,
                  height: 350,
                }}
              >
                <FaPollH
                  style={{ fontSize: "6rem", margin: "auto", color: "#DD3545" }}
                />
                <Card.Body style={{ position: "relative" }}>
                  <Card.Title style={{fontWeight:'bold'}}>  فريق تقني متكامل </Card.Title>
                  <Card.Text>
                  نحن مجموعة من المستشارين, المصممين، المبرمجين، والمهندسين، نساعد زبائننا على أن ينافسوا و يكون لهم إسمهم في السوق.
                  </Card.Text>
                  <Button
                    variant="outline-danger"
                    style={{
                      position: "absolute",
                      bottom: 5,
                      marginLeft: "auto",
                      marginRight: "auto",
                      left: 10,
                      right: 10,
                    }}
                  >
                    {" "}
                    More{" "}
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card
                style={{
                  margin: "auto",
                  width: "18rem",
                  textAlign: "center",
                  border: 0,
                  height: 350,
                }}
              >
                <FaTasks
                  style={{ fontSize: "6rem", margin: "auto", color: "#DD3545" }}
                />

                <Card.Body style={{ position: "relative" }}>
                  <Card.Title style={{fontWeight:'bold'}}>نركز على النتائج  </Card.Title>
                  <Card.Text>
                  قوم بتنفيذ مشروعك بالطريقة المناسبة ولا توجد لدينا "ثقافة الأعذار" لإكمال المشاريع. 

                  </Card.Text>
                  <Button
                    variant="outline-danger"
                    style={{
                      position: "absolute",
                      bottom: 5,
                      marginLeft: "auto",
                      marginRight: "auto",
                      left: 10,
                      right: 10,
                    }}
                  >
                    {" "}
                    More{" "}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
                    <ClientsCrusel />
      <Row
        style={{ marginTop: 100, backgroundColor: "#F0F0F0", paddingTop: 50 }}
      >
        <Col>
          <img
          alt=""
            src={logo}
            style={{
              display: "block",
              height: 100,
              width: "auto",
              marginRight: "auto",
              marginLeft: "auto",
              marginBottom: 100,
            }}
          />
        </Col>
        <Col>
          <ul style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <li style={{ fontWeight: "bold" }}>Contact Us:</li>
            <li>Phone number| +218 91-5990985|</li>
            <li>WA| +218 91-5990985 </li>
            <li>website : wahatech.ly</li>
            <li>Email: : info@wahatech.ly</li>
          </ul>
        </Col>
        <Col>
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: 8,
            }}
          >
            <Link to="/">
              <li>Home</li>
            </Link>
            <Link to="/about">
              <li>About</li>
            </Link>
            <Link to="/services">
              <li>Services</li>
            </Link>
            <Link to="/peodacts">
              <li>Prodacts</li>
            </Link>
            <Link to="/contact">
              <li>Contact</li>
            </Link>
          </ul>
        </Col>
      </Row>
    </>
  );
}

export default Home;
