import React from 'react'
import Container from "react-bootstrap/Container";

import '../servicesCompnent/servicses.css'
import {Link} from 'react-router-dom'
import logo from '../../../assets/images/logo.png'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
  


function Services() {
  return (
      <>
          <Container style={{marginTop:100}} >
     <section >
      <div className="roww">
        <h2 className="section-heading">الخدمات والحلول التي تقدمها الشركة </h2>
        <h6>من أهم المنتجات و الخدمات التي نقدمها لزبائننا الكرام.
</h6>
      </div>
      <div className="roww">
      <div className="columnn">
          <div className="cardd">
            <div className="icon-wrapperr">
            <i className="fa-solid fa-vr-cardboard"></i>
                        </div>
            <h3 className="h3"> تصميم منتجات الواقع الافتراضي</h3>
       
          </div>
        </div>
        <div className="columnn">
          <div className="cardd">
            <div className="icon-wrapperr">
            <i className="fa-solid fa-link"></i>
            </div>
             <h3 className="h3" > منظومات إدارة المبيعات "الجملة و القطاعي</h3>
       
          </div>
        </div>
        <div className="columnn">
          <div className="cardd">
            <div className="icon-wrapperr">
            <i className="fa-regular fa-mobile"></i>
            </div>
             <h3 className="h3" > تصميم و برمجة تطبيقات الهواتف المحمولة</h3>
         
          </div>
        </div>
        <div className="columnn">
          <div className="cardd">
            <div className="icon-wrapperr">
            <i className="fas fa-desktop"></i>
            </div>
             <h3 className="h3" > تصميم و برمجة المواقع الإلكترونية </h3>
        
          </div>
        </div>
        <div className="columnn">
          <div className="cardd">
            <div className="icon-wrapperr">
              <i className="fas fa-broom"></i>
            </div>
             <h3 className="h3" >تصميم واجهات المستخدم للتطبيقات و المواقع الإلكترونية
</h3>
          
          </div>
        </div>
        <div className="columnn">
          <div className="cardd">
            <div className="icon-wrapperr">
            <i className="fa-regular fa-ticket"></i>
            </div>
             <h3 className="h3" >خدمات الدعم الفني التقني للشركات</h3>
    
          </div>
        </div>
        {/* <div className="columnn">
          <div className="cardd">
            <div className="icon-wrapperr">
            <i className="fa-regular fa-manhole"></i>
             </div>
             <h3 className="h3" >NFC and RFID cardds Management Systems.</h3>
    
          </div>
        </div> */}
        <div className="columnn">
          <div className="cardd">
            <div className="icon-wrapperr">
            <i className="fa-solid fa-shield-halved"></i>
            </div>
             <h3 className="h3" >خدمات الأمن السيبراني</h3>
    
          </div>
        </div>
        <div className="columnn">
          <div className="cardd">
            <div className="icon-wrapperr">
            <i className="fa-solid fa-microchip-ai"></i>
            </div>
             <h3 className="h3" >خدمات الذكاء الإصطناعي  
</h3>
    
          </div>
        </div>
      </div>
    </section>
  </Container>

     <Row style={{ marginTop:100 , backgroundColor:'#F0F0F0' , paddingTop:50  }}>
     <Col >
         <img src={logo} style={ {display: 'block', height:100 , width:'auto' , marginRight:'auto' , marginLeft:'auto' , marginBottom:100}} />
     </Col>
     <Col>
           <ul style={{ display: 'flex' , flexDirection:'column' , gap:8 }}>
             <li style={{ fontWeight:'bold'}}>Contact Us:</li>
             <li>Phone number| +218 91-5990985|</li>
             <li>WA| +218 91-5990985 </li>
             <li>website : wahatech.ly</li>
             <li>Email: : info@wahatech.ly</li>  
           </ul>

     </Col>
       <Col>
       <ul style={{ display: 'flex' , flexDirection:'column' , justifyContent:'start'   , gap: 8}}>
             <Link to='/' ><li>Home</li></Link>
             <Link to='/about' ><li>About</li></Link>
             <Link to='/services' ><li>Services</li></Link>
             <Link to='/peodacts' ><li>Prodacts</li></Link>
             <Link to='/contact' ><li>Contact</li></Link>

           </ul>
       </Col>
</Row>
      </>

  )
}

export default Services
