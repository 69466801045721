import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import './about.css'
import {Link} from 'react-router-dom'
import logo from '../../../assets/images/logo_1.png'
import aboutimg from '../../../assets/images/aboutpagepic.png'
import   Foteer from '../helper/Foteer.js'

function About() {
  return (
    <>

<div className="con">

  
<div id="divabout">
</div>  

  <div  className="hero" style={{ height: '60vh', backgroundColor: 'black', width: '100%', display: 'flex', textAlign: 'center', justifyContent: 'center', flexDirection: 'column' }}>
    <Container>

      <div style={{ width: '60%', color: 'white', margin: 'auto' }} >
        <h1> ABOUT WAHATECH  </h1>
     

      </div>



    </Container>


  </div>

  </div>






    <Container>
      <Row className='mis'>
        <Col>
          <div  style={{
            marginTop:100,
          }}>

          <Row>
              <Col> <h4 className="about-paragraph" style={{color:'#744F9C' , fontWeight:'bold'}}>About Us </h4></Col>
           
            </Row>
            <Row>
              <Col className="about-paragraph"> <p>WAHA Tech is one of the leading Technology solutions companies in Libya, it’s branched from the most famous Expo’s Organizing companies in Libya this gives us the Access to the biggest and most quality companies database in Libya and we were able to get their trust. We worked with both private and public companies and institutions in Libya.</p></Col>
           
            </Row>
          </div>
        </Col>

        <Col>
              <img className='about-img'  src={aboutimg} />
        </Col>
      </Row>

    </Container>


    <Container style={{
     
      height:400,
    }}>
        <Row className='mis'>
          <Col>
          <Card style={{ border:'0px'  }}>
      <Card.Body>
      <div style={{ marginTop: 120, width: '50%', margin: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <img style={{width:80 , height:'auto' , padding:20}} src='https://cdn-icons-png.flaticon.com/512/993/993854.png'></img>
        </div>
        <Card.Title style={{color:'#744F9C' , textAlign:'center', fontWeight:'bold'}}>Mission</Card.Title>
       
        <Card.Text  style={{textAlign:'center'}} >
        We aim to harness technology to facilitate human life by creating technical solutions that contribute to the process of digital transformation of companies
        </Card.Text>

      </Card.Body>
    </Card>
          </Col>
          <Col>
          <Card style={{  border:'0px'   }}>
      <Card.Body>
 
      <div style={{ marginTop: 120, width: '50%', margin: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <img style={{width:80 , height:'auto' , padding:20}} src='     https://cdn-icons-png.flaticon.com/512/2923/2923835.png'></img>
        </div>
        <Card.Title style={{color:'#744F9C' , textAlign:'center' , fontWeight:'bold'}}>VALUE PROPOSITION</Card.Title>
       
        <Card.Text style={{textAlign:'center'}} >
        We provide unique software and hardware tech products for companies and business owners fast technical support.fast and high quality implementation for customized products        </Card.Text>

      </Card.Body>
    </Card>
          </Col>
        </Row>
    </Container>

    <Foteer/>
    </>

  )
}

export default About
