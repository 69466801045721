import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom'

import logo from '../../assets/images/logo.png'


function Prodacts() {
  return (
    <>
    <Container style={{marginTop:100}}>
      <h1 style={{textAlign:'center' , marginTop:200 , color:'#002581' , fontWeight:700} } >  Eventat.ly</h1>
      <Row>
        <p style={{ width:900 , textAlign:'center', marginLeft:'auto' , marginRight:'auto' , marginTop:40 , fontSize:'2rem'}}>
        هي منصة لعرض أخر الأنشطة والفعاليات من حولك (معارض - ورش العمل - أنشطة المؤسسات والمنظمات - الدوريات - البطولات - الرحلات - الجلسات الحوارية - الحفلات, إلخ..)  بكامل تفاصيلها و أنواعها في مكان واحد.        </p>
      </Row>
    </Container>

    <Container style={{marginTop:100}}>
      <h1 style={{textAlign:'center' , marginTop:200 , color:'#002581' , fontWeight:700} } >WAHA Tech Support</h1>
      <Row>
        <p style={{ width:900 , textAlign:'center', marginLeft:'auto' , marginRight:'auto' , marginTop:40 , fontSize:'2rem'}}>
        هي منصة إلكترونية للتمويل الجماعي تساعد صناع المحتوى, المؤسسات الخيرية, المؤثرين, أصحاب المشاريع الناشئةعلى إطلاق حملاتهم للتمويل و قبول الدعم و التبرعات من جمهورهم بطرق الدفع الإلكتروني على المنصة. </p>
      </Row>
    </Container>

    <Container style={{marginTop:100}}>
      <h1 style={{textAlign:'center' , marginTop:200 , color:'#002581' , fontWeight:700} } >VR Expo</h1>
      <Row>
        <p style={{ width:900 , textAlign:'center', marginLeft:'auto' , marginRight:'auto' , marginTop:40 , fontSize:'2rem'}} />
        منصة دورات هي منصة تساعد المدربين و المؤسسات التعليمية على إطلاق دوراتهم الإلكترونية  بطرق سهلة و بسيطة و تعرض المحتوى للطلبة على المنصة بطريقة إحترافية و سهلة الإستعمال لتساعدهم على الوصول للمحتوى التعليمي ذو الجودة العالية في التخصصات الأكاديمية و المهنية.              </Row>
    </Container>
    <Row style={{ marginTop:100 , backgroundColor:'#F0F0F0' , paddingTop:50  }}>
            <Col >
                <img src={logo} style={ {display: 'block', height:100 , width:'auto' , marginRight:'auto' , marginLeft:'auto' , marginBottom:100}} />
            </Col>
            <Col>
                  <ul style={{ display: 'flex' , flexDirection:'column' , gap:8 }}>
                    <li style={{ fontWeight:'bold'}}>Contact Us:</li>
                    <li>Phone number| +218 91-5990985|</li>
                    <li>WA| +218 91-5990985 </li>
                    <li>website : wahatech.ly</li>
                    <li>Email: : info@wahatech.ly</li>  
                  </ul>

            </Col>
              <Col>
              <ul style={{ display: 'flex' , flexDirection:'column' , justifyContent:'start'   , gap: 8}}>
                    <Link to='/' ><li>Home</li></Link>
                    <Link to='/about' ><li>About</li></Link>
                    <Link to='/services' ><li>Services</li></Link>
                    <Link to='/peodacts' ><li>Prodacts</li></Link>
                    <Link to='/contact' ><li>Contact</li></Link>

                  </ul>
              </Col>
      </Row>
     
    </>
  )
}

export default Prodacts
