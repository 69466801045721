import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import logo from '../../assets/images/logo.png'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';



import {Link} from 'react-router-dom'
   const sendData  = () => {
    return (
      <Toast>
      <Toast.Header>
        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
        <strong className="me-auto">Bootstrap</strong>
        <small>11 mins ago</small>
      </Toast.Header>
      <Toast.Body>Hello, world! This is a toast message.</Toast.Body>
    </Toast>
    )
   }

function Contact() {

    return (
      <>
        <Container style={{marginTop:100}}>
        <Row style={{ width:'50%' , margin:'auto' }}>
          <Col>
          <Form style={{width:'100%'}}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Your Name</Form.Label>
          <Form.Control type="email" placeholder="Enter Your Name" />
        
        </Form.Group>
  
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" placeholder="Email" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Message</Form.Label>
          <Form.Control type="text" placeholder="Enter Message Content" />
        </Form.Group>
        <Button onClick={sendData()}  variant="primary" type="submit" style={{marginRight:'auto' , marginLeft: 'auto' , width:'20%'  , textAlign:'center' , display:'block'}}>
          Send 
        </Button>
      </Form>
          </Col>
        </Row>
      </Container>
  

       
      <Row style={{  position:'fixed' , bottom:0 , backgroundColor:'#F0F0F0' , paddingTop:50  , width:'100%' }}>
  <Col >
      <img src={logo} style={ {display: 'block', height:100 , width:'auto' , marginRight:'auto' , marginLeft:'auto' , marginBottom:100}} />
  </Col>
  <Col>
        <ul style={{ display: 'flex' , flexDirection:'column' , gap:8 }}>
          <li style={{ fontWeight:'bold'}}>Contact Us:</li>
          <li>Phone number| +218 91-5990985|</li>
          <li>WA| +218 91-5990985 </li>
          <li>website : wahatech.ly</li>
          <li>Email: : info@wahatech.ly</li>  
        </ul>

  </Col>
    <Col>
    <ul style={{ display: 'flex' , flexDirection:'column' , justifyContent:'start'   , gap: 8}}>
          <Link to='/' ><li>Home</li></Link>
          <Link to='/about' ><li>About</li></Link>
          <Link to='/services' ><li>Services</li></Link>
          <Link to='/peodacts' ><li>Prodacts</li></Link>
          <Link to='/contact' ><li>Contact</li></Link>

        </ul>
    </Col>
  </Row>



      </>


)
    }
export default Contact
