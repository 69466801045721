import React , {useState} from 'react'

import { links, social } from "./data"
import logo from "../../../assets/images/logo.png"
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';

import './nav.css'




function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false)


  return (


    <>

      <Container>
      <nav className="navigation">
      <Link to="/" className="brand-name">
       <img  style={{width:200}} src={logo} />
      </Link>
      <button className="hamburger"
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
      >
        {/* icon from heroicons.com */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
          className={
            isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
          }>
          
          <div>
          <ul >
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="/prodacts">Products</Link>
          </li>
          <li>
            <Link to="/clients">Clients</Link>
          </li>
          <li>
            <Link to="/contact">Contact us</Link>
          </li>
          
          {/* <li >
            <Link to="/ar/" style={{fontWeight:'bold'}}> AR <spna><img style={{width:20,height:'auto'}} src={"https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Flag_of_Saudi_Arabia_%281938%E2%80%931973%29.svg/1200px-Flag_of_Saudi_Arabia_%281938%E2%80%931973%29.svg.png"}></img></spna></Link>
          </li> */}
        </ul>

          </div>
      </div>
    </nav>


      </Container>
    </>

  )
}

export default Navbar
