import React from 'react'
import './foter.css'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom'
import logo from '../../../assets/images/logo.png'
function Foteer() {
  return (

    <footer class="footer-14398">
      
        <div class="container">
          <div class="row mb-5">
            <div class="col-md-3">
              <a href="#" class="footer-site-logo">WahaTech</a>
              <p>WAHA Tech is one of the leading Technology solutions companies in Libya, it’s branched from the most famous Expo’s Organizing companies in Libya this gives us the Access to the biggest and most quality companies database in Libya and we were able to get their trust. We worked with both private and public companies and institutions in Libya.</p>
            </div>

            <div class="col-md-2 ml-auto">
              <h3>More</h3>
              <ul class="list-unstyled links">
                <li><a href="#">Events</a></li>
                <li><a href="#">Blog</a></li>
                <li><a href="#">Clients</a></li>
                <li><a href="#">Products</a></li>
              </ul>
            </div>

            <div class="col-md-2 ml-auto">
              <h3>Contact Information</h3>
              <ul class="list-unstyled links">
                <li>Phone : <a href="#">+218 91-5990985</a></li>
                <li>WA: <a href="#">+218 91-5990985</a></li>
                <li>Website : <a href="#">wahatech.ly</a></li>
                <li>Email : <a href="mailto:info@wahatech.ly">info@wahatech.ly</a></li>
              </ul>
            </div>

            <div class="col-md-2 ml-auto">
              <h3>Quick Links</h3>
              <ul class="list-unstyled links">
                <li><a href="/">Home</a></li>
                <li><a href="/services">Services</a></li>
                <li><a href="#">Career</a></li>
                <li><a href="/contact">Contacts</a></li>
              </ul>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-12 pb-4">
              <div class="line"></div>
            </div>
            <div class="col-md-6 text-md-left">
              <ul class="list-unstyled link-menu nav-left">
                <li><a href="#">Privacy Policy</a></li>
                <li><a href="#">Terms &amp; Conditions</a></li>
              </ul>
            </div>
            <div class="col-md-6 text-md-right">
              <ul class="list-unstyled social nav-right">
                <li><a href="#"><span class="icon-twitter"></span></a></li>
                <li><a href="#"><span class="icon-instagram"></span></a></li>
                <li><a href="#"><span class="icon-facebook"></span></a></li>
                <li><a href="#"><span class="icon-pinterest"></span></a></li>
              </ul>
            </div>
          </div>

          <div class="row">
            <div class="col-md-7">
              <p><small>All Copyright saved</small></p>
            </div>
          </div>
        </div>
   
    </footer>




  )

    

  
}

export default Foteer