import React from 'react'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Foteer from '../helper/Foteer';
import waha from '../../../assets/images/wahaexpo.png'
import arina from '../../../assets/images/Arinasexpo.png'
import tqnya from '../../../assets/images/taqnya.png'
import dz from '../../../assets/images/madeindz.png'

import './clients.css'

function Clients() {
  return (
    <>

<div className="con">

  
<div id="divclients">
</div>  

  <div  className="hero" style={{ height: '60vh', backgroundColor: 'black', width: '100%', display: 'flex', textAlign: 'center', justifyContent: 'center', flexDirection: 'column' }}>
    <Container>

      <div style={{ width: '60%', color: 'white', margin: 'auto' }} >
        <h1>OUR CLIENTS</h1>
    

      </div>



    </Container>


  </div>

  </div>






        <Container>
        <Row style={{marginTop:100 , marginBottom:200 }}>



          <Col>
          <img className='logo-img-clients logo-size' style={{width:300 , height:'auto'}}  src={waha} />
          </Col>



          <Col>
          <img className='logo-img-clients edit'  style={{width:250 ,marginTop:30 ,  height:'auto'}}  src={tqnya} />
   
          </Col>




          <Col style={{position:'relative'}}>
        <img  className='logo-img-clients edit'  style={{width:'auto' ,position:'absolute' ,   height:150}} src={arina} />
     
          </Col>



        </Row>

    </Container>
    <Foteer /> 
    </>
  )
}

export default Clients