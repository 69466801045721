import Home from './en/components/Home/Home';
import Navbaren  from './en/components/helper/Navbar';
// import './public/style/main.css'
import {
  Route,
  Routes,
} from "react-router-dom";

import About  from './en/components/About/About';
import Services  from './en/components/servicesCompnent/Services';
import Prodacts  from './en/components/Prodacts/Prodacts';
import Contact  from './en/components/Contact/Contact';
import Clients from './en/components/Clients/Clients';




// ar imports
import Homear from './ar/components/Homear';
import Navbarar  from './ar/components/Navbarar';
import Aboutar  from './ar/components/Aboutar';
import Servicesar  from './ar/components/servicesCompnent/Servicesar';
import Prodactsar  from './ar/components/Prodactsar';
import Contactar  from './ar/components/Contactar';
import Clientsar from './ar/components/Clientsar';

// import '../src/en/components/servicesCompnent/servicses.css'



function App() {
  return (
    <>

      {/* Rotuers For English V */}
      <Navbaren />
      <Routes>
      <Route  path='/' element={<Home />} />
      <Route  path='/about' element={<About />} />
      <Route  path='/services' element={<Services />} />
      <Route  path='/prodacts' element={<Prodacts />} />
      <Route  path='/contact' element={<Contact />} />
      <Route  path='/clients' element={<Clients />} />
      </Routes>





      <Routes>
      <Route exact path='/ar' element={<Homear />} />
      <Route  path='/ar/aboutar' element={<Aboutar />} />
      <Route  path='/ar/services' element={<Servicesar />} />
      <Route  path='/ar/prodacts' element={<Prodactsar />} />
      <Route  path='/ar/contact' element={<Contactar />} />
      <Route  path='/ar/clients' element={<Clientsar />} />
      </Routes>
    </>
  );
}

export default App;
  