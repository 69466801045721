import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom'

import logo from '../../../assets/images/logo.png'
import Foteer from '../helper/Foteer';

import enently from '../../../assets/images/EVENTLY.png'
import SADAKA from '../../../assets/images/SADAKA-100.jpg'
import dawrat from '../../../assets/images/dawrat.png'
import './prodacts.css'
function Prodacts() {
  return (
    <>
 <div className="con">

  
<div id="divprodacts">
</div>  

  <div  className="hero" style={{ height: '60vh', backgroundColor: 'black', width: '100%', display: 'flex', textAlign: 'center', justifyContent: 'center', flexDirection: 'column' }}>
    <Container>

      <div style={{ width: '60%', color: 'white', margin: 'auto' }} >
        <h1>OUR PRODACTS</h1>      
      

      </div>



    </Container>


  </div>

  </div>








    <Container>
        <Row style={{ padding:100}}>



          <Col>
          <Row><img  style={{width:300 , height:'auto'}}  src={enently} /></Row>
          <Row> <p style={{marginTop:50}} > It is a platform to display the latest activities and events around you (exhibitions- workshops - activities of institutions and organizations - periodicals - tournaments - trips - dialogue sessions - parties, etc..) and its details and types in one place</p></Row>
          </Col>



          <Col>
          <Row><img style={{width:200 , height:'auto'}}  src={SADAKA} /></Row>
          <Row> <p> 
          It is an electronic crowdfunding platform that helps content creators, charities, influencers, startup owners) to launch their campaigns for funding and accept support and donations from their audience using electronic payment methods on the platform.            
            </p></Row>
          </Col>




          <Col style={{position:'relative'}}>
          <Row><img className='dwarat-logo' style={{width:'auto' ,position:'absolute'  ,top:-40 ,  height:'auto'}} src={dawrat} /></Row>
          <Row> <p style={{marginTop:100}}> 
          It is a platform that helps trainers and educational institutions to launch their electronic courses in easy and simple ways and presents the content to students on the platform in a professional and easy-to-use manner to help them access educational content of high quality in academic and professional disciplines            </p></Row>
          </Col>



        </Row>

    </Container>

    <Foteer />

    </>
  )
}

export default Prodacts
