/* eslint-disable react/jsx-no-undef */
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import "../../public/style/main.css";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo_1.png";
import Man from "../../../assets/images/manlaptop.png";
import Rocket from "../../../assets/images/rocket.png";
import Golbal from "../../../assets/images/global.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import clientarepartner from '../../../assets/images/clientsarepartners.png'
import integeretedtecnecalthem from '../../../assets/images/techfoucsed.png'
import wefocusonresult from '../../../assets/images/foucsonresult.png'
import responsipilty from '../../../assets/images/transperncy.png'

import Foteer from "../helper/Foteer";


import vr from '../../../assets/images/VR.png'
import b2b from '../../../assets/images/b2b.png'
import mobile from '../../../assets/images/mobile.png'
import sec from '../../../assets/images/security1.png'
import techsupport from '../../../assets/images/tech support (2).png'
import ui from '../../../assets/images/ui.png'
import webdev from '../../../assets/images/webdev.png'

import './Home.css'
import step1 from '../../../assets/images/step1.png'
import step2 from '../../../assets/images/step2.png'
import step3 from '../../../assets/images/step3.png'
import step4 from '../../../assets/images/step4.png'
import step5 from '../../../assets/images/step5.png'
import step6 from '../../../assets/images/step6.png'



import client1 from '../../../assets/images/Arinasexpo.png'
import client2 from '../../../assets/images/wahaexpo.png'
import client3 from '../../../assets/images/madeindz.png'


import prodacut1 from '../../../assets/images/dawrat.png'
import prodacut2 from '../../../assets/images/EVENTLY.png'
import prodacut3 from '../../../assets/images/SADAKA-100.jpg'


import { useNavigate } from "react-router-dom";

import { FaUsers, FaCheck, FaPollH, FaTasks } from "react-icons/fa";
import Navbar from "../helper/Navbar";


import './Home.css'
function Home() {

  const navigate = useNavigate();

  const Go_to_page = (e, path) => {
    e.preventDefault();
    navigate(`${path}`);
  }
  return (
    <>
    <div className="con">

  
    <div id="div1">
    </div>  

      <div  className="hero" style={{ height: '60vh', backgroundColor: 'black', width: '100%', display: 'flex', textAlign: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <Container>

          <div style={{ width: '60%', color: 'white', margin: 'auto' }} >
            <h1>WAHA FOR TECHNOLOGY SERVICES </h1>
            <h3>One of the leading Technology solutions
              companies in  Libya
            </h3>

          </div>



        </Container>


      </div>

      </div>
















      <Container style={{ marginTop: 100 }}>

        <h1 style={{
          textAlign: "center",
          fontSize: 50,
          marginBottom: 90

        }}>OVERVIEW</h1>
        <Row style={{


        }}>
          <Col >
            <div className="clip-img" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'  }}>
              <img style={{
                width: 600,
                height: 'auto'
              }} src='https://images.unsplash.com/photo-1606836591695-4d58a73eba1e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80' />
            </div>
          </Col>
          <Col>
            <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

              <div style={{ height: '100%', }}>
                <p style={{
                  fontSize: 25,
                  marginTop:60
                }}>
                  it’s branched from the most famous Expo’s Organizing companies in Libya. This gives us the Access to the biggest and most quality companies database in Libya and we were able to get their trust. We worked with both private and public companies and institutions in Libya.
                </p>
              </div>
              <div style={{ marginTop: 120, width: '50%', margin: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <button  onClick={ e => Go_to_page(e , '/about')} className="btn btn-danger" style={{ width: 200 }}>More</button>

        </div>
            </Row>
            <Row>

            </Row>
          </Col>

        </Row>
    
      </Container>





      <div style={{
        padding: 40,

        marginTop: 90,

      }}>
        <h1 style={{ textAlign: 'center' }}>Our Values</h1>

        <Container style={{
          marginTop: 100,
          width: '90%',

        }}>
          <Row>
            <Col>
              <Row >

                <Row>
                  <center>

                    <img style={{
                      height: 150,
                      width: 'auto'
                    }} src={clientarepartner} />
                  </center>
                </Row>

                <Row >

                  <h6 style={{ textAlign: 'center', fontSize: 30, marginTop: 50 }}>Clients are partners</h6>
                </Row>
              </Row>
            </Col>
            <Col>
              <Row>

                <Row>
                  <center>
                    <img style={{
                      height: 150,
                      width: 'auto'
                    }} src={responsipilty} />
                  </center>
                </Row>
                <Row >
                  <h6 style={{ textAlign: 'center', fontSize: 30, marginTop: 50 }}>Responsibility and achievement</h6>
                </Row>
              </Row>
            </Col>
            <Col>
              <Row>

                <Row>
                  <center>
                    <img style={{
                      height: 150,
                      width: 'auto'
                    }} src={integeretedtecnecalthem} />
                  </center>
                </Row>

                <Row >
                  <h6 style={{ textAlign: 'center', fontSize: 30, marginTop: 50 }}>Integrated technical team</h6>
                </Row>
              </Row>
            </Col>
            <Col>
              <Row>

                <Row>
                  <center>

                    <img style={{
                      height: 150,
                      width: 'auto'
                    }} src={wefocusonresult} />
                  </center>
                </Row>
                <Row >
                  <h6 style={{ textAlign: 'center', fontSize: 30, marginTop: 50 }}>We focus on result</h6>
                </Row>
              </Row>
            </Col>
          </Row>



        </Container>

        <div style={{  width: '50%', margin: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <button  onClick={ e => Go_to_page(e , '/about')} className="btn btn-danger" style={{ marginTop: 100, width: 200 }}>More</button>

        </div>
      </div>













      <div style={{ padding: 100 }}>

        <h1 style={{ textAlign: 'center' }}>Our Services</h1>
        <Container>
          <Row >
            <Col className="service-card" >
              <Row>
                <img style={{ padding: 40 }} src={ui} />
              </Row>
              <Row>
                <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>  UI/UX</h3>
              </Row>
              <Row>

              </Row>
            </Col>





            <Col className="service-card" >
              <Row>
                <img style={{ padding: 40 }} src={vr} />
              </Row>
              <Row>
                <h3 style={{ textAlign: 'center', fontWeight: 'bold' }} >Artificial Intelligent</h3>

              </Row>
              <Row>

              </Row>
            </Col>






            <Col className="service-card" >
              <Row>
                <img style={{ padding: 40 }} src={sec} />
              </Row>
              <Row>
                <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>Security</h3>
              </Row>
              <Row>

              </Row>
            </Col>





            <Col className="service-card" >
              <Row>
                <img style={{ padding: 40 }} src={techsupport} />
              </Row>
              <Row>
                <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}> Technical support services</h3>
              </Row>
              <Row>

              </Row>
            </Col>



          </Row>









          <Row >
            <Col className="service-card" >
              <Row>
                <img style={{ padding: 40 }} src={vr} />
              </Row>
              <Row>
                <h3 style={{ textAlign: 'center', fontWeight: 'bold' }} >Vr Services</h3>
              </Row>
              <Row>

              </Row>
            </Col>





            <Col className="service-card" >
              <Row>
                <img style={{ padding: 40 }} src={b2b} />
              </Row>
              <Row>
                <h3 style={{ textAlign: 'center', fontWeight: 'bold' }} > B2B and B2C Commerce</h3>
              </Row>
              <Row>

              </Row>
            </Col>






            <Col className="service-card" >
              <Row>
                <img style={{ padding: 40 }} src={mobile} />
              </Row>
              <Row>
                <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>  mobile App Development </h3>
              </Row>
              <Row>

              </Row>
            </Col>





            <Col className="service-card" >
              <Row>
                <img style={{ padding: 40 }} src={webdev} />
              </Row>
              <Row>
                <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>Web applications Development</h3>
              </Row>
              <Row>

              </Row>
            </Col>



          </Row>


          <div style={{  width: '50%', margin: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <button onClick={ e => Go_to_page(e , '/services')} className="btn btn-danger" style={{ marginTop: 60, width: 200 }}>More</button>

          </div>

        </Container>


      </div>











      <Container>
        <h1 style={{ textAlign: 'center' , fontWeight:'bold' }}>Steps to contract with us</h1>
        <p style={{ textAlign: 'center' }}>in order to keep pace with technological development in this era, we offer you innovative software solutions</p>
        <Row>
          <Col className="steps"  >
            <div >
              <Row>

                <Col >
                  <Row>
                    <Row><h3 style={{ textAlign: 'left', fontWeight: 'bold', color: "#744F9C" }}>Connect with us</h3> </Row>
                    <Row >
                      <p style={{ textAlign: 'left', width: 300 }}> By contacting our customer service or through social media platforms, put forward your project idea or schedule an interview</p>
                    </Row>
                  </Row>
                </Col>
                <Col  ><img style={{ width: 100 }} src={step1}></img></Col>
              </Row>
            </div>

          </Col>

          <Col className="steps" >
            <Row>

              <Col  >
                <Row>
                  <Row><h3 style={{ textAlign: 'left', fontWeight: 'bold', color: "#744F9C" }}>presentation </h3> </Row>
                  <Row >
                    <p style={{ textAlign: 'left', width: 300 }}>

                      The company's specialized team presents a proposal for your project idea and details, and the offer includes prices and implementation mechanism from the time and date of delivery

                    </p>
                  </Row>
                </Row>
              </Col>
              <Col ><img style={{ width: 100 }} src={step2}></img></Col>
            </Row>

          </Col>





          <Col className="steps" >
            <Row>

              <Col  >
                <Row>
                  <Row><h3 style={{ textAlign: 'left', fontWeight: 'bold', color: "#744F9C" }}>the plan</h3> </Row>
                  <Row >
                    <p style={{ textAlign: 'left', width: 300 }}>
                      The specialized team performs a complete analysis of the project, determines its main pillars, and develops a complete implementation plan for the project details as requested by the client
                    </p>
                  </Row>
                </Row>
              </Col>
              <Col  ><img style={{ width: 100 }} src={step3}></img></Col>
            </Row>

          </Col>






        </Row>

        <Row>
          <Col className="steps"  >
            <Row>

              <Col  >
                <Row>
                  <Row><h3 style={{ textAlign: 'left', fontWeight: 'bold', color: "#744F9C" }}>construction phase</h3> </Row>
                  <Row >
                    <p style={{ textAlign: 'left', width: 300 }}>

                      The specialized team implements the described plan and according to the customer’s details, where the construction will be with the latest international technologies and secure programming languages

                    </p>
                  </Row>
                </Row>
              </Col>
              <Col  ><img style={{ width: 100 }} src={step4}></img></Col>
            </Row>

          </Col>

          <Col className="steps"  >
            <Row>

              <Col  >
                <Row>
                  <Row><h3 style={{ textAlign: 'left', fontWeight: 'bold', color: "#744F9C" }}>delivery stage</h3> </Row>
                  <Row >
                    <p style={{ textAlign: 'left', width: 300 }}>

                      The company's specialized team presents a proposal for your project idea and details, and the offer includes prices and implementation mechanism from the time and date of delivery


                    </p>
                  </Row>
                </Row>
              </Col>
              <Col ><img style={{ width: 100 }} src={step5}></img></Col>
            </Row>

          </Col>





          <Col className="steps" >
            <Row >

              <Col >
                <Row>
                  <Row><h3 style={{ textAlign: 'left', fontWeight: 'bold', color: "#744F9C" }}>technical support</h3> </Row>
                  <Row >
                    <p style={{ textAlign: 'left', width: 300 }}>

                      The technical support team follows up the project after its launch and provides all solutions to the technical problems presented by the client
                    </p>
                  </Row>
                </Row>
              </Col>
              <Col  ><img style={{ width: 100 }} src={step6}></img></Col>
              
            </Row>

          </Col>

          <div style={{  width: '50%', margin: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'center' , marginBottom:60 }}>
            <button onClick={ e => Go_to_page(e , '/contact')} className="btn btn-danger" style={{backgroundColor:'white', color:'black' ,  marginTop: 60, width: 200 }}>Contact US </button>

          </div>




        </Row>
     

      </Container>









                      <Container style={{marginBottom:100}}>
                        <h1 style={{textAlign:'center' , marginBottom:50 , fontWeight:'bold'}}>Our Products</h1>
                            <Row className="row-logos" >

                              <Col>
                              <img className="logo-img" style={{width:150}}  src={prodacut1} />
                              </Col>
                              <Col>
                              <img   className="logo-img" style={{width:200}} src={prodacut2} />
                              </Col>
                              <Col>
                              <img  className="logo-img"  style={{marginLeft:30}}  src={prodacut3} />
                              </Col>
                            </Row>
                      </Container>







                      <Container style={{marginBottom:100}}>
                        <h1 style={{textAlign:'center' , marginBottom:50 , fontWeight:'bold'}}>Our Clients</h1>
                            <Row className="row-logos" >

                              <Col>
                              <img className="logo-img edit" style={{width:100}}  src={client1} />
                              </Col>
                              <Col>
                              <img   className="logo-img" style={{width:200}} src={client2} />
                              </Col>
                              <Col>
                              <img  className="logo-img"  style={{width:200}}  src={client3} />
                              </Col>
                            </Row>
                      </Container>


                    <Foteer/>




    </>
  );
}

export default Home;
