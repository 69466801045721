import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom'
import logo from '../../assets/images/logo_1.png'



function About() {
  return (
    <>
       
      <Container style={{marginTop:100}}>
      <h1 style={{textAlign:'center' , marginTop:200 , color:'#002581' , fontWeight:700} } > Meet Wahatech Comany </h1>
      <Row>
        <p style={{ width:900 , textAlign:'center', marginLeft:'auto' , marginRight:'auto' , marginTop:40 , fontSize:'2rem'}}>
        الواحة للخدمات التقنية هي شركة ليبية متخصصة في مجال تقنية وأمن المعلومات، حيث نهدف إلى تسخير التكنولوجيا في تسهيل الأعمال عن طريق إنشاء حلول تقنية تساهم في عملية التحول الرقمي للشركات حيث نقدم خدمات تطوير مواقع الإنترنت وتطبيقات الموبايل مدمجة بأحدث تقنيات الذكاء الاصطناعي و الواقع الإفتراضي بالاضافة إلى أنظمة إدارة موارد المؤسسات وأنظمة المبيعات وتوفير البُنية التحتية والاستشارات التقنية اللاّزمة للمؤسسات لسلاسة عملها و لتأمين البيانات ومتابعة أمنها السيبراني.        </p>
      </Row>


    </Container>

    <Container style={{marginTop:100}}>
      <h1 style={{textAlign:'center' , marginTop:200 , color:'#002581' , fontWeight:700} } >  أهدافنا</h1>
      <Row>
        <p style={{ width:900 , textAlign:'center', marginLeft:'auto' , marginRight:'auto' , marginTop:40 , fontSize:'2rem'}}>

        نهدف إلى تقديم خدمات وحلول تقنية مبتكرة وموثوقة تلبي احتياج عملائنا وخلق بيئة تدعم ريادة الأعمال التقنية من خلال فريق محترف.         </p>
      </Row>


    </Container>


    <Container style={{marginTop:100}}>
      <h1 style={{textAlign:'center' , marginTop:200 , color:'#002581' , fontWeight:700} } >رؤيتنا</h1>
      <Row>
        <Col>
          <p style={{ width:900 , textAlign:'center', marginLeft:'auto' , marginRight:'auto' , marginTop:40 , fontSize:'2rem'}}>
          التطوير المستمر لتكون ليبيا دولة ذات حكومة إلكترونية،
          </p>


        </Col>
      </Row>


    </Container>

        
    <Row style={{ marginTop:100 , backgroundColor:'#F0F0F0' , paddingTop:50  }}>
            <Col >
                <img src={logo} style={ {display: 'block', height:100 , width:'auto' , marginRight:'auto' , marginLeft:'auto' , marginBottom:100}} />
            </Col>
            <Col>
                  <ul style={{ display: 'flex' , flexDirection:'column' , gap:8 }}>
                    <li style={{ fontWeight:'bold'}}>Contact Us:</li>
                    <li>Phone number| +218 91-5990985|</li>
                    <li>WA| +218 91-5990985 </li>
                    <li>website : wahatech.ly</li>
                    <li>Email: : info@wahatech.ly</li>  
                  </ul>

            </Col>
              <Col>
              <ul style={{ display: 'flex' , flexDirection:'column' , justifyContent:'start'   , gap: 8}}>
                    <Link to='/' ><li>Home</li></Link>
                    <Link to='/about' ><li>About</li></Link>
                    <Link to='/services' ><li>Services</li></Link>
                    <Link to='/peodacts' ><li>Prodacts</li></Link>
                    <Link to='/contact' ><li>Contact</li></Link>

                  </ul>
              </Col>
      </Row>
     
    </>

  )
}

export default About
